export default {

  "EOS Go 精选项目": [
    {
      name: "EOS 骑士",
      description: "EOS骑士是第一款在EOS区块链上运行的手机游戏",
      url: "https://eosknights.io",
      image: "eosknights.png"
    },{
      name: "BetDice",
      description: "区块链摇骰子的魅力游戏!",
      url: "https://betdice.one/",
      image: "betdice.png"
    },{
      name: "像素大师",
      description: "一款EOS得到BM官方“指点”的火爆画图游戏",
      url: "https://pixelmaster.io/",
      image: "pixelmaster.png"
    },{
      name: "Scatter",
      description: "Scatter是—款安装在chorme浏览器上的插件EOS钱包",
      url: "https://get-scatter.com/",
      image: "scatter.jpg"
    },{
      name: "TP钱包",
      description: "去中心化通用数字钱包",
      url: "https://www.mytokenpocket.vip/en/",
      image: "tokenpocket.png"
    },{
      name: "Chintai",
      description: "EOS代币租赁平台",
      url: "https://www.chintai.io/",
      image: "chintai.png"
    },{
      name: "Everipedia",
      description: "EOS上的维基百科",
      url: "https://everipedia.org",
      image: "everipedia.png"
    },{
      name: "EOS Infra",
      description: "为 EOS 主网上的 DApp 开发者提供专业的 API 服务 ",
      url: "https://eosinfra.io",
      image: "eosinfra.png"
    }

  ],
  "社区消息": [
    {
      name: "EOSGO社区周报",
      description: "EOS社区更新和新闻的主流媒体",
      url: "https://t.me/@eosgo_news",
      image: "eosgonews.png"
    },{
      name: "EOS.IO官方网站",
      description: "官方公告，核心技术，开发资源",
      url: "https://eos.io/",
      image: "eos.png"
    },{
      name: "EOS Go论坛",
      description: "EOS上超级节点，开发者，和社区成员聚集的家园",
      url: "https://forums.eosgo.io/",
      image: "eosgo-forums.png"
    },{
      name: "Reddit /r/eos",
      description: "SEOS最丰富新闻的宝库",
      url: "https://www.reddit.com/r/eos",
      image: "reddit.png"
    }
  ],
  "新手指南" : [
    {
      name: "EOSIO 常见问题",
      description: "EOS.io常见问题",
      url: "https://eos.io/faq",
      image: "eosio.jpg"
    },{
      name: "EOS档案库",
      description: "开源EOS文档",
      url: "https://www.eosdocs.io/",
      image: "eosdocs.jpg"
    },{
      name: "EOS初学者指南",
      description: "终极的EOS入门指南",
      url: "https://medium.com/@eosgo/eos-beginners-anatomy-of-an-eos-account-8bfff3e1716",
      image: "eosgobeginners.png"
    },{
      name: "EOS 训练营",
      description: "适应不同技能水平的EOS教程网站 ",
      url: "https://eosbootcamp.com/",
      image: "eosbootcamp.png"
    },{
      name: "ABC of EOS",
      description: "EOS术语表",
      url: "https://www.eoscanada.com/en/abc-eos",
      image: "abcofeos.png"
    }, {
      name: "EOS Name Service",
      description: "Buy & Sell Custom EOS Accounts",
      url: "https://www.eosnameservice.io/?ref=poweredbyyou",
      image: "ens.png"
    }
  ],

  "区块链浏览器": [
    {
      name: "EOSX",
      description: "最快的EOS区块链浏览器",
      url: "https://eosx.io",
      image: "eosx.png"
    },{
      name: "eosflare.io",
      description: "EOS区块链浏览器",
      url: "https://eosflare.io/",
      image: "eosflare.png"
    },{
      name: "eosq",
      description: "高精度区块浏览器",
      url: "https://eosq.app/",
      image: "eosq.jpg"
    },{
      name: "scaneos.io",
      description: "EOS扫描仪和资源管理器",
      url: "https://scaneos.io/",
      image: "scaneos.png"
    },{
      name: "Bloks",
      description: "EOS区块链浏览器",
      url: "https://bloks.io/",
      image: "bloks.png"
    },{
      name: "EOS Tracker",
      description: "EOS追踪器和资源管理器",
      url: "https://eostracker.io/",
      image: "eostracker.png"
    },{
      name: "EOSpark",
      description: "由中国团队打造的强大EOS区块浏览器",
      url: "https://eospark.com/",
      image: "eospark.png"
    }
  ],
  // Telegram will be a problem, everyone will want to be there. Low entry standard
  // "Telegram": [
  //   {
  //     name: "EOS Official",
  //     description: "Official telegram of the EOS community",
  //     url: "https://t.me/joinchat/AAAAAEQbOeucnaMWN0A9dQ",
  //     image: "eosio.jpg"
  //   },{
  //     name: "EOS Go",
  //     description: "Telegram of the biggest EOS community",
  //     url: "https://t.me/EOSgo",
  //     image: "eosgo.png"
  //   },{
  //     name: "EOS Asia",
  //     description: "Discussion of all EOS Asia BP initiatives",
  //     url: "https://t.me/EOSAsia",
  //     image: "eosasia-telegram.jpg"
  //   },{
  //     name: "EOS Jobs",
  //     description: "Best place to search for devs or work related to EOS",
  //     url: "https://t.me/EOS_jobs",
  //     image: "eosjobs.jpg"
  //   }
  // ],
  "钱包和身份": [
    {
      name: "Scatter",
      description: "可作为桌面应用或Chrome扩展程序使用.",
      url: "https://get-scatter.com/",
      image: "scatter.jpg"
    },{
      name: "imToken",
      description: "功能丰富的数字资产钱包",
      url: "https://token.im/",
      image: "imtoken.jpg"
    },{
      name: "TokenPocket",
      description: "EOS手机钱包",
      url: "https://www.mytokenpocket.vip/en/",
      image: "tokenpocket.png"
    },{
      name: "Ledger Nano S",
      description: "市场上最受欢迎的EOS硬件钱包",
      url: "https://support.ledgerwallet.com/hc/en-us/articles/360008913653-EOS-EOS-",
      image: "ledger.jpg"
    },{
      name: "EOS Voter",
      description: "一款开源的功能限制版本的轻钱包",
      url: "https://github.com/greymass/eos-voter/blob/master/README.md",
      image: "Greymass.png"
    },{
      name: "cobo",
      description: "数字币虚拟币钱包APP",
      url: "https://cobo.com/",
      image: "cobo.png"
    },{
      name: "Starteos",
      description: "安全的EOS手机钱包",
      url: "https://www.starteos.io/",
      image: "starteos.jpg"
    },{
      name: "EOS Lynx",
      description: "快速便捷的EOS钱包",
      url: "https://eoslynx.com/",
      image: "lynxwallet.png"
    },{
      name: "SimplEOS",
      description: "专为EOS生态系统制作的钱包",
      url: "https://eosrio.io/simpleos/",
      image: "simpleos.jpg"
    },{
      name: "MEET.ONE",
      description: "EOS多功能钱包",
      url: "https://meet.one/download/en",
      image: "meetone.png"
    },{
      name: "NOVA Wallet",
      description: "最人性化的EOS手机钱包",
      url: "http://eosnova.io",
      image: "novawallet.png"
    },{
      name: "LUBANSO",
      description: "保障您的EOS",
      url: "https://www.lubanso.com/",
      image: "lubanso.jpg"
    },{
      name: "Infinito Wallet",
      description: "多资产通用钱包",
      url: "https://www.infinitowallet.io/",
      image: "infinito.png"
    },{
      name: "MYKEY",
      description: "简单易用的多链钱包",
      url: "https://mykey.org",
      image: "mykey.png"
    },{
      name: "Lumi Wallet",
      description: "匿名多币种钱包：BTC，EOS，ETH等",
      url: "https://lumiwallet.com",
      image: "lumi-wallet.png"
    }
  ],
  "账户管理": [
    {
      name: "EOSX Web Wallet",
      description: "用户友好的EOS网络钱包",
      url: "https://www.eosx.io/tools/vote",
      image: "eosx.png"
    },{
      name: "Bloks Toolkit",
      description: "完整的EOS区块浏览器和钱包",
      url: "https://bloks.io/wallet",
      image: "bloks.png"
    },{
      name: "Bank of Staked",
      description: "自助式CPU＆NET资源自动售货机",
      url: "https://eoslaomao.com/bankofstaked/",
      image: "bankofstaked.png"
    },{
      name: "EOS Toolkit",
      description: "您的EOS帐户的工具包",
      url: "https://eostoolkit.io/home",
      image: "eostoolkit.jpg"
    },{
      name: "EOSAuthority",
      description: "EOS钱包及浏览器,包含25种工具,支持所有侧链甚至测试网",
      url: "https://eosauthority.com/",
      image: "eosauthority.jpg"
    },{
      name: "EOS Account Creator",
      description: "注册EOS账户的好网站",
      url: "https://eos-account-creator.com/",
      image: "eos-account-creator.jpg"
    },{
      name: "EOS Cannon Tool",
      description: "EOS 佳能在线工具",
      url: "https://tool.eoscannon.io/#/",
      image: "cannon-toolkit.png"
    },{
      name: "EOS Charge",
      description: "预估在EOS不同DAPP上可用操作和操作的资源损耗",
      url: "https://www.eoscharge.io",
      image: "eosny.png"
    },{
      name: "EOS Name Swaps",
      description: "出售您的EOS帐户",
      url: "https://www.eosnameswaps.com/",
      image: "eosnameswaps.jpg"
    },{
      name: "CPU Emergency",
      description: "30秒内获得CPU资源",
      url: "https://www.cpuemergency.com/",
      image: "cpu-emergency.png"
    },{
      name: "EOS FeeXplorer",
      description: "FeeXplorer是用于交易RAM的工具",
      url: "https://eos.feexplorer.io/",
      image: "feexplorer.jpeg"
    },{
      name: "ZEOS",
      description: "自己动手注册帐户的门户",
      url: "https://zeos.co/",
      image: "zeos.png"
    },{
      name: "Name Vault",
      description: "EOS帐户生成器和创建者",
      url: "https://namevault.co/",
      image: "namevault.png"
    },{
      name: "EOS Payout",
      description: "视觉投资组合",
      url: "https://www.eospayout.com/",
      image: "eospayout.jpg"
    },{
      name: "iBank",
      description: "财务管理DApp",
      url: "https://ibankeos.io/eos",
      image: "ibank.jpeg"
    }
  ],
  "超级节点": [
    {
      name: "Titan 投票摘要",
      description: "可视化计算超级节点投票",
      url: "https://voters.eostitan.com/voters/200/0/21",
      image: "titan.png"
    },{
      name: "丛林Testnet",
      description: "丛林测试网的监视器",
      url: "http://jungle.cryptolions.io/",
      image: "junglenet.png"
    },{
      name: "AlohaEOS 研究门户",
      description: "EOS投票代理和BP研究门户",
      url: "https://www.alohaeos.com/tools",
      image: "alohaeos.png"
    },{
      name: "Cypto麒麟测试网",
      description: "加密麒麟测试网的监视器",
      url: "https://tools.cryptokylin.io/",
      image: "cryptokylin.png"
    },{
      name: "EOSX区块浏览器",
      description: "EOS全方位主网监控",
      url: "https://www.eosx.io",
      image: "eosx.png"
    },{
      name: "黑名单",
      description: "EOS黑名单智能合约",
      url: "https://github.com/EOSLaoMao/theblacklist/",
      image: "laomao.jpg"
    },{
      name: "投票分析工具",
      description: "用于分析每个节点投票的工具",
      url: "https://www.eosbeijing.one/data/#/diff/",
      image: "eosbeijing.png"
    },{
      name: "EOSStats",
      description: "鼓励EOS投票过程透明",
      url: "https://www.eosstats.io/",
      image: "eosny.png"
    },{
      name: "Glass",
      description: "保护EOS网络的工具",
      url: "https://glass.cypherglass.com/map/main/top50",
      image: "glass.png"
    },{
      name: "EOS Network Monitor",
      description: "一款主链监控器",
      url: "http://eosnetworkmonitor.io/",
      image: "cryptolions.png"
    },{
      name: "EOS Test Cave",
      description: "验证的自动化测试框架",
      url: "https://github.com/EOS-BP-Developers/EOS-Test-Cave",
      image: "eostestcave.png"
    }
  ],
  "项目目录": [
    {
      name: "State of the DApps",
      description: "介绍DApps的浏览网站",
      url: "https://www.stateofthedapps.com/",
      image: "stateofthedapps.jpg"
    },{
      name: "DappRadar",
      description: "Dapp市场数据和Dapp分发平台",
      url: "https://dappradar.com/eos-dapps/",
      image: "dappradar.png"
    },{
      name: "EOSindex",
      description: "介绍 EOS 项目的索引",
      url: "https://eosindex.io/",
      image: "eosindex.png"
    },{
      name: "IMEOS",
      description: "EOS生态的资讯门户网站",
      url: "https://imeos.one/project/",
      image: "imeos.jpg"
    },{
      name: "DERU",
      description: "DERU是潜在的革命性债券体系",
      url: "http://deru.io/",
      image: "deru.png"
    }
    // ,{
    //   name: "ROSHAMBO",
    //   description: "Rock, paper, scissors in Jungle or Mainnet",
    //   url: "http://roshambo.cryptolions.io/",
    //   image: "roshambo.jpg"
    // }
  ],
  "交易所": [
    {
      name: "BigONE",
      description: "全球领先的数字资产交易及托管平台",
      url: "https://big.one/",
      image: "bigone.png"
    },{
      name: "币安",
      description: "区块链数字资产交易品牌，向全球提供广泛的数字货币交易",
      url: "https://www.binance.com/en",
      image: "binance.png"
    },{
      name: "DeltaDex",
      description: "第一个基于 EOS 构建的完全去中心化交易所",
      url: "https://deltadex.io/",
      image: "deltadex.png"
    },{
      name: "畅思",
      description: "专注 EOS 生态的精品交易平台",
      url: "https://chaince.com/",
      image: "chaince.png"
    },
    {
      name: "NewDEX",
      description: "首家EOS去中心化交易所",
      url: "https://newdex.io/",
      image: "newdex.jpeg"
    },
    {
      name: "Bitfinex",
      description: "Bitfinex是著名的老牌交易所",
      url: "https://www.bitfinex.com/",
      image: "bitfinex.png"
    },{
      name: "KuCoin",
      description: "行业领先的数字货币交易平台",
      url: "https://www.kucoin.com/#/",
      image: "kucoin.png"
    },{
      name: "Bithumb",
      description: "韩国虚拟货币交易平台",
      url: "https://www.bithumb.com/",
      image: "bithumb.png"
    },{
      name: "EOSex",
      description: "基于EOS社区的混合引擎交易平台",
      url: "https://eosex.com/#/",
      image: "eosex.png"
    },{
      name: "DEXEOS",
      description: "第一个基于EOS的分散交换平台",
      url: "https://dexeos.io/#/",
      image: "dexeos.png"
    },{
      name: "Token.Store",
      description: "A trustless exchange for EOS tokens",
      url: "https://token.store/eos/",
      image: "tokenstore.png"
    },{
      name: "Switcheo",
      description: "基于NEO的去中心化交易所",
      url: "https://switcheo.exchange/",
      image: "switcheo.jpg"
    }
  ],
  "帮助与争议": [
    {
      name: "EOS 911",
      description: "为帮助诈骗或帐户丢失的社区人员而生",
      url: "https://eos911.io/content/welcome",
      image: "eos911.jpg"
    },{
      name: "ECAF",
      description: "解决EOSIO区块链上的争议",
      url: "https://eoscorearbitration.io/",
      image: "ecaf.png"
    },{
      name: "LINK",
      description: "加密社区的倡导者",
      url: "https://thelink.one/",
      image: "link.png"
    }
  ],
  "开发人员资源": [
    {
      name: "官方开发门户",
      description: "开始为EOS开发而生",
      url: "https://developers.eos.io/",
      image: "eos.png"
    },{
      name: "EOSIO StackExchange",
      description: "eosio区块链平台上构建分布式应用程序的开发人员的全球资源",
      url: "https://developers.eos.io/",
      image: "stackexchange.png"
    },{
    name: "Awesome EOS Tutorials",
    description: "EOSAsia优秀的EOS开发教程集合",
    url: "https://github.com/eosasia/awesome-eos-tutorials",
    image: "eosasia.png"
  },{
    name: "DAPP Development",
    description: "DAPP开发信息的开源目录",
    url: "https://www.eosdocs.io/dappdevelopment/",
    image: "eosdocs.jpg"
  },{
    name: "Node Management Scripts",
    description: "主网节点管理脚本",
    url: "https://github.com/CryptoLions/EOS-MainNet",
    image: "cryptolions.png"
  },{
    name: "EOS Daily Snapshot",
    description: "EOS Snapshot可以帮助快速的恢复数据",
    url: "https://www.eossnapshots.io/",
    image: "eosny.png"
  },{
    name: "EOS Hackathon",
    description: "EOSIO全球区块链黑客马拉松",
    url: "https://eoshackathon.io/",
    image: "eos-hackathon.jpg"
  },{
    name: "QuillAudits",
    description: "基于EOS的智能合约和DApp的审核平台",
    url: "https://audits.quillhash.com/smart-contract-audit",
    image: "quillaudits_logo.png"
  },{
    name: "EOS Infra",
    description: "DApp的稳定可靠的API服务",
    url: "https://eosinfra.io",
    image: "eosinfra.png"
  },{
    name: "EOS Studio",
    description: "在线合约开发和分享平台",
    url: "https://eosstudio.io",
    image: "eosstudio.jpeg"
  },{
    name: "dfuse",
    description: "强大可靠的API，可在EOS上构建下一代应用",
    url: "hhttps://www.dfuse.io",
    image: "dfuse.jpg"
  },{
    name: "FireWall X",
    description: "强大的EOS智能合约防火墙",
    url: "https://firewallx.io/index-en.html",
    image: "firewallx.png"
  }
]
}
