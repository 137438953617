// Queries and caches data from coinmarketcap

import axios from 'axios'
const _marketData = {}
const MARKET_API_ENDPOINT = 'https://api.coinmarketcap.com/v2/ticker/1765/'
const GECKO_API_ENDPOINT = 'https://api.coingecko.com/api/v3/simple/price?ids=eos&vs_currencies=usd&include_market_cap=true&include_24hr_change=true'
const MODE = 'gecko'

export default () => {
  return new Promise((resolve, reject)=>{
    if (_marketData.price) {
      resolve(_marketData)
    } else if (MODE === 'gecko') {
      axios.get(GECKO_API_ENDPOINT)
        .then(response => {
          if (response.data.eos) {
            Object.assign(_marketData, {
              price: response.data.eos.usd,
              marketCap: response.data.eos.usd_market_cap,
              percentChange24h: response.data.eos.usd_24h_change.toFixed(2),
            })
            resolve(_marketData)
          } else {
            reject("response.data.data.quotes.USD.price not found")
          }
        }).catch(error => {
          reject(error)
        })
    } else {
      axios.get(MARKET_API_ENDPOINT)
        .then(response => {
          if (response.data.data.quotes.USD.price) {
            Object.assign(_marketData, {
              price: response.data.data.quotes.USD.price,
              marketCap: response.data.data.quotes.USD.market_cap,
              percentChange24h: response.data.data.quotes.USD.percent_change_24h,
            })
            resolve(_marketData)
          } else {
            reject("response.data.data.quotes.USD.price not found")
          }
        }).catch(error => {
          reject(error)
        })
    }
  })
}