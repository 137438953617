import React from "react";
import styled from "styled-components";
import C from "../../colors";
import { eosjs } from "../../utils/api";
import getMarketData from "../../utils/market-data";
import { FormattedMessage } from "react-intl";
import axios from "axios";

const cleanNumber = (num, decimalPlaces = 4) => {
  // Renders a number with commas for thousands and a <span className="decimal"> for digits past the decimal point
  if (num && num.toString) {
    const floatRegex = /^[+-]?([0-9]*[.])?[0-9]+/;
    const floatString = num.match(floatRegex)[0];
    const truncated = parseFloat(num).toFixed(decimalPlaces);
    const str = floatString.length < truncated.length ? floatString : truncated;

    const afterFloat = num.toString().replace(/\d+.?(\d+)?/, "");
    // const str = num.toString()
    const split = str.split(".");
    const withCommasBeforeDecimal = split[0].replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );

    const fullString =
      withCommasBeforeDecimal + (split[1] ? "." + split[1] : "") + afterFloat;
    return fullString;
  } else return num;
};
const addUtmToUrl = url => {
  return (
    url && url + (url.indexOf("?") !== -1 ? "&" : "?") + "utm_source=eosgo.io"
  );
};

const MarketCap = styled.div`
  margin-bottom: 30px;
  .title {
    @media (max-width: 550px) {
      display: none;
    }
  }

  .title-mobile {
    color: #9498b9;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
    @media (min-width: 550px) {
      display: none !important;
    }
  }

  .float-right {
    float: right;
  }
  .table-cont {
    margin-bottom: 30px;
    border: 1px solid ${C.light};
    padding: 16px 20px;
    border-radius: 4px;
    overflow: hidden;
    @media (max-width: 600px) {
      overflow: scroll;
      padding: 10px 12px;
    }
  }
  table {
    width: 100%;
    margin-bottom: 10px;
    color: ${C.grayDark};
    td,
    th {
      padding: 5px 20px 0 0;
      &,
      > img,
      > span,
      > strong {
        vertical-align: middle;
      }
      img {
        width: 24px;
        height: 24px;
        margin-right: 5px;
        border-radius: 3px;
      }
      @media (max-width: 600px) {
        padding-right: 10px;
      }
    }
    th {
      color: ${C.blue2};
      font-size: 80%;
      text-transform: uppercase;
      letter-spacing: 0.667px;
      border-bottom: 1px solid ${C.light};
      padding-bottom: 5px;
      vertical-align: bottom;
    }
    .min1 {
      min-width: 160px;
    }
    .max1 {
      max-width: 160px;
      text-overflow: ellipsis;
      a {
        max-width: 160px;
        text-overflow: ellipsis;
      }
    }
    .right-align {
      text-align: right;
    }
    .green {
      color: ${C.green};
    }
    .red {
      color: ${C.red};
    }
    .gray {
      color: ${C.gray};
    }
    .dark {
      color: ${C.dark};
    }
    .rank,
    .light {
      color: ${C.gray2};
    }
    .nowrap {
      white-space: nowrap;
    }
    .token-name {
      white-space: nowrap;
    }

    @media (max-width: 600px) {
      .rank {
        padding-right: 0;
      }
    }
  }
  .table-footer {
    margin-top: 5px;
    padding-top: 5px;
    border-top: 1px solid ${C.light};
  }
  @media (max-width: 800px) {
    .mobile-hidden {
      display: none;
    }
  }
`;

export class Fallback extends React.Component {
  render() {
    return (
      <MarketCap>
        <div className="clearfix">
          <h1 className="title"> EOS Market Cap </h1>
        </div>
        <div className="table-cont">
          Error loading market cap information. Please reload the page to retry.
        </div>
      </MarketCap>
    );
  }
}
export default class Index extends React.Component {
  constructor() {
    super();
    this.state = {
      tokens: {
        "eosio.token|eos": {
          tokenName: "EOS",
          tokenPriceInEOS: 1
        }
      },
      tableLimit: 11,
      marketData: []
    };
  }

  componentDidMount() {
    this._mounted = true;
    getMarketData()
      .then(response => {
        if (!this._mounted) return;
        this.setState({ eos: response });
      })
      .catch(error => console.error(error));

    window.marketCap = this;

    // axios({
    //   method: 'get',
    //   url: 'https://marketcap.one/api/1.0/tokens',
    //   headers:{
    //     "MCO-AUTH": "3afzQNIyaunq69h6t9uM"
    //   }
    // })
    //       .then(res => {
    //         const persons = res.data;
    //         this.setState({ persons });
    //         console.log('TESTTTT', persons)
    //       })

    this.callMarketCapOne();
  }
  componentWillUnmount() {
    this._mounted = false;
  }

  callMarketCapOne = async () => {
    let res = await axios({
      method: "get",
      url: "https://marketcap.one/api/1.0/tokens",
      headers: {
        "MCO-AUTH": "3afzQNIyaunq69h6t9uM"
      }
    });
    let marketData = res.data;
    await this.setState({ marketData });
  };

  buildTable() {
    const ret = [];
    for (let i in this.state.marketData.data) {
      const tokenData = this.state.marketData.data[i];
      const tokenContract = tokenData.contract;
      const tokenName = tokenData.name;
      const tokenPriceInEOS = tokenData.current_price;
      const percentChange24h = Number(tokenData.price_change_24hr).toFixed(2);
      const marketCapUsd = Number(tokenData.current_marketcap);
      const marketCapUsdClean = "$" + cleanNumber(marketCapUsd.toFixed(0));
      const supply = Number(tokenData.current_supply).toFixed(0);
      const tokenIcon = tokenData.icon;
      const tokenSymbol = tokenData.symbol;
      const key = i;
      const tokenObj = {
        tokenContract,
        tokenName,
        tokenPriceInEOS,
        percentChange24h,
        marketCapUsdClean,
        marketCapUsd,
        supply,
        tokenIcon,
        tokenSymbol
      };

      if (this.state.marketData) {
        if (tokenName === "EOS")
          tokenObj.percentChange24h = this.state.eos.percentChange24h;
        //force token price in EOS of EOS to be 1
        if (tokenName === "EOS") tokenObj.tokenPriceInEOS = 1;

        // Calculate token price
        tokenObj.tokenPriceInUSD =
          tokenObj.tokenPriceInEOS * this.state.eos.price;
        tokenObj.tokenPriceInUSDClean =
          "$" + cleanNumber(tokenObj.tokenPriceInUSD.toFixed(6));
      }

      ret.sort((a, b) => b.marketCapUsd - a.marketCapUsd);
      //removing Bitcoin
      if (tokenName !== "Bitcoin") {
        ret.push(tokenObj);
      }
    }
    return ret;
  }

  getTotalMarketCap() {
    let total = this.buildTable().reduce((acc, b) => {
      if (b.tokenName === "EOS") return acc;
      return acc + b.marketCapUsd;
    }, 0);
    if (isNaN(total)) {
      console.error("[getTotalMarketCap]: Returned NaN");
      return "";
    }
    if (total === 0) return "";
    return "$" + cleanNumber(total.toFixed(2));
  }

  render() {
    const totalTokenMarketCap = this.getTotalMarketCap();

    return (
      <MarketCap>
        {/* { JSON.stringify(this.state.socketData) } */}
        {/* {console.log(`THIS IS MARKETCAP.ONE ${(this.state.eos)}`)} */}
        <div className="clearfix">
          {totalTokenMarketCap && (
            <div className="float-right">
              <FormattedMessage id="marketcap-alltokens" />{" "}
              {totalTokenMarketCap}
            </div>
          )}
          <h1 className="title-mobile">
            {" "}
            <FormattedMessage id="marketcap-mobile" />{" "}
          </h1>
          <h1 className="title">
            {" "}
            <FormattedMessage id="marketcap-title" />{" "}
          </h1>
        </div>
        <div className="table-cont">
          <table>
            <tbody>
              <tr>
                <th> # </th>
                <th className="min1">
                  {" "}
                  <FormattedMessage id="marketcap-token" />{" "}
                </th>
                <th className="right-align">
                  {" "}
                  <FormattedMessage id="marketcap-marketcap-usd" />{" "}
                </th>
                <th>
                  {" "}
                  <FormattedMessage id="marketcap-price-eos" />{" "}
                </th>
                <th>
                  {" "}
                  <FormattedMessage id="marketcap-price-usd" />{" "}
                </th>
                <th className="right-align">
                  {" "}
                  <FormattedMessage id="marketcap-24h" />{" "}
                </th>
                <th className="mobile-hidden">
                  {" "}
                  <FormattedMessage id="marketcap-circulating-supply" />{" "}
                </th>
                <th className="mobile-hidden">
                  {" "}
                  <FormattedMessage id="marketcap-contract" />{" "}
                </th>
                {/* <th> <FormattedMessage id="marketcap-website" /> </th> */}
              </tr>

              {this.buildTable()
                .slice(0, this.state.tableLimit)
                .map((row, i) => {
                  let percentChangeClass = "";
                  if (row.percentChange24h > 0) percentChangeClass = "green";
                  else if (row.percentChange24h < 0) percentChangeClass = "red";
                  else percentChangeClass = "gray";

                  let name = row.tokenName;

                  return (
                    <tr key={i}>
                      <td className="rank"> {i > 0 && i} </td>
                      <td className="token-name">
                        <img alt={row.tokenName} src={row.tokenIcon} />
                        <strong className="dark"> {row.tokenSymbol} </strong>
                        <span className="light">({name})</span>
                      </td>
                      <td className="right-align"> {row.marketCapUsdClean} </td>
                      <td> {row.tokenPriceInEOS} </td>
                      <td> {row.tokenPriceInUSDClean} </td>
                      <td className={"right-align " + percentChangeClass}>
                        {row.percentChange24h}%
                      </td>
                      <td className="mobile-hidden nowrap">
                        {" "}
                        {cleanNumber(row.supply)} {row.tokenSymbol}
                      </td>
                      <td className="mobile-hidden">
                        <a
                          href={
                            "https://www.eosx.io/account/" + row.tokenContract
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {row.tokenContract}
                        </a>
                      </td>
                      {/* <td className="max1">
                <a href={addUtmToUrl(this.getUrl(row))}>
                  {this.getUrl(row)}
                </a>
              </td> */}
                    </tr>
                  );
                })}
              {this.buildTable().length <= 1 && (
                <tr>
                  <td></td>
                  <td colSpan="100" className="light">
                    {" "}
                    <FormattedMessage id="marketcap-loading" />{" "}
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {this.state.tableLimit < 20 && this.buildTable().length > 20 && (
            <div className="table-footer">
              <a
                href="#"
                onClick={() => {
                  this.setState({ tableLimit: 1000 });
                }}
              >
                <FormattedMessage id="marketcap-viewmore" />
              </a>
            </div>
          )}
        </div>
      </MarketCap>
    );
  }
}
