import React from "react";
import LINKS from "../../links";
import LINKSCN from "../../links-cn";
import Tile from "../components/tile";
import MarketCap from "../components/marketcap";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Helmet from "react-helmet";
import { FormattedMessage } from "react-intl";
import reverseTransformLocale from "../components/LocaleTransform/reverseTransformLocale"

// import axios from 'axios'

// const isSsrEnv = () => typeof location === 'undefined';

// const BugsnagErrorBoundary = !isSsrEnv() && require('bugsnag-js')({
//     apiKey: '5c15c8e14c05cdc5899dc911e94d3056',
//     releaseStage: process.env.NODE_ENV,
//   }).use(require('bugsnag-react')(React))

const renderLinkSection = (name, list) => (
  <div className="link-section" key={name}>
    <h3 className="title">{name}</h3>
    <div className="row-flex">
      {list.map((linkData, i) => (
        <Tile key={i} {...linkData} />
      ))}
    </div>
  </div>
);

const Index = ({ pageContext: { locale } }) => {
  // let { locale } = pageContext

  //LinksLoad is used to localize the links in the homepage
  let LinksLoad 
  let SEOTitle
  let SEODescription


if (locale ==="cn") {
  LinksLoad = Object.keys(LINKSCN).map(key => renderLinkSection(key, LINKSCN[key]))
  SEOTitle = "EOS Go - 最大的EOS社区，EOSIO新闻的主要来源和领导者";
  SEODescription = "EOS Go是最大的EOS社区，最初的EOS主网启动，是EOS Go组织社群力量一起发布了主网。 从建立的第一天起，EOS Go就是EOS项目，EOS代币市值排名，EOS新闻和项目列表的聚集地。同时，EOS GoEOSIO新闻的先驱，提供所有EOSIO区块链的最新采用信息，公告，报告和价格走势。eosgo.io 是 EOSIO 上最大访问量和最受欢迎 的新闻⻔户网站。EOS Go的使命是为了EOS和整个社区的明天而努力。";

} else {
  LinksLoad = Object.keys(LINKS).map(key => renderLinkSection(key, LINKS[key]))
  SEOTitle = "EOS Go - Homepage for all things EOS";
  SEODescription = "EOS Go is the largest EOS community and the go to place for EOS projects, EOS tokens market cap rankings, EOS news and DApp explorer.";

}


  return (
    <Layout locale={reverseTransformLocale(locale)}>
      <SEO title={SEOTitle} description={SEODescription} />
      <Helmet>
        <link rel="preconnect" href="https://api.coingecko.com" crossorigin />
        <link rel="preconnect" href="https://marketcap.one" />
      </Helmet>

      <div className="notice">
        <strong>
          <FormattedMessage id="homepage-notice" />{" "}
        </strong>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://forms.gle/DdTugjzK7HrYWiHw9"
        >
          <FormattedMessage id="homepage-notice-cta" />
        </a>
      </div>

      <MarketCap />

      {LinksLoad}

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `{ "@context": "http://schema.org",
      "@type": "Organization",
      "name": "EOS Go ",
      "url": "https://eosgo.io",
      "sameAs": [
        "https://www.facebook.com/eosgoio/",
        "https://twitter.com/go_eos"
      ]}`
        }}
      />
    </Layout>
  );
};

export default Index;
