export default {

  "EOS Go Selected Projects": [
    {
      name: "Pixel Master",
      description: "World's first collaborative artwork based on blockchain",
      url: "https://pixelmaster.io/",
      image: "pixelmaster.png"
    },{
      name: "Everipedia",
      description: "Everipedia's IQ Network is a decentralized Encyclopedia",
      url: "https://everipedia.org",
      image: "everipedia.png"
    },{
      name: "TokenPocket",
      description: "EOS mobile light-wallet",
      url: "https://www.mytokenpocket.vip/en/",
      image: "tokenpocket.png"
    },{
      name: "EOS Infra",
      description: "Stable and Reliable API access for DApps",
      url: "https://eosinfra.io",
      image: "eosinfra.png"
    }
  ],
  "Community & Announcements": [
    {
      name: "EOS Community Updates",
      description: "The main source for EOS community updates and news",
      url: "https://t.me/@eosgo_news",
      image: "eosgonews.png"
    },{
      name: "EOS.IO Official Site",
      description: "Official announcements, core tech, dev resources",
      url: "https://eos.io/",
      image: "eos.png"
    },{
      name: "EOS Go Forums",
      description: "For BPs, developers, and any community members",
      url: "https://forums.eosgo.io/",
      image: "eosgo-forums.png"
    },{
      name: "Reddit /r/eos",
      description: "Stay up to date on EOS news",
      url: "https://www.reddit.com/r/eos",
      image: "reddit.png"
    }
  ],
  "Getting Started" : [
    {
      name: "EOSIO FAQ",
      description: "EOS.io Frequently Asked Questions",
      url: "https://eos.io/faq",
      image: "eosio.jpg"
    },{
      name: "EOSDocs",
      description: "Open Source EOS Documentation",
      url: "https://www.eosdocs.io/",
      image: "eosdocs.jpg"
    },{
      name: "EOS Beginners Guide",
      description: "The Definitive EOS Starter's Guide",
      url: "https://medium.com/@eosgo/eos-beginners-anatomy-of-an-eos-account-8bfff3e1716",
      image: "eosgobeginners.png"
    },{
      name: "EOS Bootcamp",
      description: "Educational site for everyone involved in EOS",
      url: "https://eosbootcamp.com/",
      image: "eosbootcamp.png"
    },{
      name: "ABC of EOS",
      description: "A glossary of EOS terms",
      url: "https://www.eoscanada.com/en/abc-eos",
      image: "abcofeos.png"
    }, {
      name: "EOS Name Service",
      description: "Buy & Sell Custom EOS Accounts",
      url: "https://www.eosnameservice.io/?ref=poweredbyyou",
      image: "ens.png"
    }
  ],

  "Block Explorers": [
    {
      name: "EOSX",
      description: "Fastest EOS Block Explorer",
      url: "https://eosx.io",
      image: "eosx.png"
    },{
      name: "eosflare.io",
      description: "EOS blockchain explore",
      url: "https://eosflare.io/",
      image: "eosflare.png"
    },{
      name: "eosq",
      description: "High-precision block explorer",
      url: "https://eosq.app/",
      image: "eosq.jpg"
    },{
      name: "scaneos.io",
      description: "EOS scanner and explorer",
      url: "https://scaneos.io/",
      image: "scaneos.png"
    },{
      name: "Bloks",
      description: "EOS blockchain explorer",
      url: "https://bloks.io/",
      image: "bloks.png"
    },{
      name: "EOS Tracker",
      description: "EOS tracker and explorer",
      url: "https://eostracker.io/",
      image: "eostracker.png"
    },{
      name: "EOSpark",
      description: "Powerful EOS block explorer built by a team from China",
      url: "https://eospark.com/",
      image: "eospark.png"
    }
  ],
  // Telegram will be a problem, everyone will want to be there. Low entry standard
  // "Telegram": [
  //   {
  //     name: "EOS Official",
  //     description: "Official telegram of the EOS community",
  //     url: "https://t.me/joinchat/AAAAAEQbOeucnaMWN0A9dQ",
  //     image: "eosio.jpg"
  //   },{
  //     name: "EOS Go",
  //     description: "Telegram of the biggest EOS community",
  //     url: "https://t.me/EOSgo",
  //     image: "eosgo.png"
  //   },{
  //     name: "EOS Asia",
  //     description: "Discussion of all EOS Asia BP initiatives",
  //     url: "https://t.me/EOSAsia",
  //     image: "eosasia-telegram.jpg"
  //   },{
  //     name: "EOS Jobs",
  //     description: "Best place to search for devs or work related to EOS",
  //     url: "https://t.me/EOS_jobs",
  //     image: "eosjobs.jpg"
  //   }
  // ],
  "Wallets & Identity": [
    {
      name: "Scatter",
      description: "Available as desktop app or Chrome extension.",
      url: "https://get-scatter.com/",
      image: "scatter.jpg"
    },{
      name: "imToken",
      description: "Feature-rich digital asset wallet",
      url: "https://token.im/",
      image: "imtoken.jpg"
    },{
      name: "TokenPocket",
      description: "EOS mobile light-wallet",
      url: "https://www.mytokenpocket.vip/en/",
      image: "tokenpocket.png"
    },{
      name: "Ledger Nano S",
      description: "Most popular EOS hardware wallet in the market",
      url: "https://support.ledgerwallet.com/hc/en-us/articles/360008913653-EOS-EOS-",
      image: "ledger.jpg"
    },{
      name: "EOS Voter",
      description: "Light wallet designed for the EOS blockchain by GreyMass",
      url: "https://github.com/greymass/eos-voter/blob/master/README.md",
      image: "Greymass.png"
    },{
      name: "cobo",
      description: "HD Wallet for EOS blockchain",
      url: "https://cobo.com/",
      image: "cobo.png"
    },{
      name: "Starteos",
      description: "Secure EOS mobile wallet",
      url: "https://www.starteos.io/",
      image: "starteos.jpg"
    },{
      name: "EOS Lynx",
      description: "Fast and easy EOS wallet",
      url: "https://eoslynx.com/",
      image: "lynxwallet.png"
    },{
      name: "SimplEOS",
      description: "EOS wallet by EOS Rio",
      url: "https://eosrio.io/simpleos/",
      image: "simpleos.jpg"
    },{
      name: "MEET.ONE",
      description: "EOS multifunctional wallet",
      url: "https://meet.one/download/en",
      image: "meetone.png"
    },{
      name: "NOVA Wallet",
      description: "Most User-friendly Mobile EOS Wallet",
      url: "http://eosnova.io",
      image: "novawallet.png"
    },{
      name: "LUBANSO",
      description: "Safeguard for Your EOS",
      url: "https://www.lubanso.com/",
      image: "lubanso.jpg"
    },{
      name: "Infinito Wallet",
      description: "Infinito Wallet is a multi-asset universal wallet",
      url: "https://www.infinitowallet.io/",
      image: "infinito.png"
    },{
      name: "MYKEY",
      description: "Most reliable self-sovereign identity system",
      url: "https://mykey.org",
      image: "mykey.png"
    },{
      name: "Lumi Wallet",
      description: "Anonymous Multi-currency wallet: BTC, EOS, ETH and more",
      url: "https://lumiwallet.com",
      image: "lumi-wallet.png"
    }
  ],
  "Account Management": [
    {
      name: "EOSX Web Wallet",
      description: "User-friendly EOS web wallet",
      url: "https://www.eosx.io/tools/vote",
      image: "eosx.png"
    },{
      name: "Bloks Toolkit",
      description: "A full block explorer and wallet for EOS",
      url: "https://bloks.io/wallet",
      image: "bloks.png"
    },{
      name: "Bank of Staked",
      description: "A self-serve CPU&NET resource vending machine",
      url: "https://eoslaomao.com/bankofstaked/",
      image: "bankofstaked.png"
    },{
      name: "EOS Toolkit",
      description: "A toolkit for your EOS account",
      url: "https://eostoolkit.io/home",
      image: "eostoolkit.jpg"
    },{
      name: "EOSAuthority",
      description: "Account Alerts, ETH to EOS account lookup",
      url: "https://eosauthority.com/",
      image: "eosauthority.jpg"
    },{
      name: "EOS Account Creator",
      description: "Paid Account Creation",
      url: "https://eos-account-creator.com/",
      image: "eos-account-creator.jpg"
    },{
      name: "EOS Cannon Tool",
      description: "Toolkit made by EOS Cannon",
      url: "https://tool.eoscannon.io/#/",
      image: "cannon-toolkit.png"
    },{
      name: "EOS Charge",
      description: "Custom account CPU report",
      url: "https://www.eoscharge.io",
      image: "eosny.png"
    },{
      name: "EOS Name Swaps",
      description: "Sell your EOS account",
      url: "https://www.eosnameswaps.com/",
      image: "eosnameswaps.jpg"
    },{
      name: "CPU Emergency",
      description: "0.50 EOS of CPU delegated to you for 4 hours",
      url: "https://www.cpuemergency.com/",
      image: "cpu-emergency.png"
    },{
      name: "EOS FeeXplorer",
      description: "FeeXplorer is a tool for trading RAM",
      url: "https://eos.feexplorer.io/",
      image: "feexplorer.jpeg"
    },{
      name: "ZEOS",
      description: "Do-it-yourself account registration portal",
      url: "https://zeos.co/",
      image: "zeos.png"
    },{
      name: "Name Vault",
      description: "EOS account generator & creator",
      url: "https://namevault.co/",
      image: "namevault.png"
    },{
      name: "EOS Payout",
      description: "The visual investment portfolio",
      url: "https://www.eospayout.com/",
      image: "eospayout.jpg"
    },{
      name: "iBank",
      description: "A financial management DApp",
      url: "https://ibankeos.io/eos",
      image: "ibank.jpeg"
    }
  ],
  "Block Producers": [
    {
      name: "Titan Votes Summary",
      description: "Visualize the share and the votes received by BPs",
      url: "https://voters.eostitan.com/voters/200/0/21",
      image: "titan.png"
    },{
      name: "Jungle Testnet",
      description: "A monitor of the Jungle Testnet",
      url: "http://jungle.cryptolions.io/",
      image: "junglenet.png"
    },{
      name: "AlohaEOS Research Portal",
      description: "EOS Vote Proxy and BP Research Portal",
      url: "https://www.alohaeos.com/tools",
      image: "alohaeos.png"
    },{
      name: "CyptoKylin Testnet",
      description: "A monitor of the Crypto Kylin Testnet",
      url: "https://tools.cryptokylin.io/",
      image: "cryptokylin.png"
    },{
      name: "EOSX Monitor",
      description: "All round mainnet monitor",
      url: "https://www.eosx.io",
      image: "eosx.png"
    },{
      name: "The Blacklist",
      description: "Blacklist smart contract for EOS",
      url: "https://github.com/EOSLaoMao/theblacklist/",
      image: "laomao.jpg"
    },{
      name: "Vote Analysis Tool",
      description: "Tool meant to analyze each vote of BPs",
      url: "https://www.eosbeijing.one/data/#/diff/",
      image: "eosbeijing.png"
    },{
      name: "EOSStats",
      description: "Encouraging transparency into the EOS Voting process",
      url: "https://www.eosstats.io/",
      image: "eosny.png"
    },{
      name: "Glass",
      description: "A Tool to Protect the EOS Network",
      url: "https://glass.cypherglass.com/map/main/top50",
      image: "glass.png"
    },{
      name: "EOS Network Monitor",
      description: "A popular main chain monitor",
      url: "http://eosnetworkmonitor.io/",
      image: "cryptolions.png"
    },{
      name: "EOS Test Cave",
      description: "Suite of automated scripts",
      url: "https://github.com/EOS-BP-Developers/EOS-Test-Cave",
      image: "eostestcave.png"
    }
  ],
  "Project Directories": [
    {
      name: "State of the DApps",
      description: "Explore decentralized applications",
      url: "https://www.stateofthedapps.com/",
      image: "stateofthedapps.jpg"
    },{
      name: "DappRadar",
      description: "DAPPs Ranked by daily volume",
      url: "https://dappradar.com/eos-dapps/",
      image: "dappradar.png"
    },{
      name: "EOSindex",
      description: "List of Projects based around the EOS blockchain platform",
      url: "https://eosindex.io/",
      image: "eosindex.png"
    },{
      name: "IMEOS",
      description: "Directory of Projects made by the IMEOS team",
      url: "https://imeos.one/project/",
      image: "imeos.jpg"
    },{
      name: "DERU",
      description: "DERU is a potentially revolutionary bond system",
      url: "http://deru.io/",
      image: "deru.png"
    }
    // ,{
    //   name: "ROSHAMBO",
    //   description: "Rock, paper, scissors in Jungle or Mainnet",
    //   url: "http://roshambo.cryptolions.io/",
    //   image: "roshambo.jpg"
    // }
  ],
  "Exchanges": [
    {
      name: "BigONE",
      description: "Most secure and convenient cryptocurrency exchange",
      url: "https://big.one/",
      image: "bigone.png"
    },{
      name: "Binance",
      description: "Binance Exchange is one of the fastest growing and popular",
      url: "https://www.binance.com/en",
      image: "binance.png"
    },{
      name: "DeltaDex",
      description: "DeltaDex is a true DEX built on EOS",
      url: "https://deltadex.io/",
      image: "deltadex.png"
    },{
      name: "Chaince",
      description: "Trading platform of blockchain assets around EOS",
      url: "https://chaince.com/",
      image: "chaince.png"
    },
    {
      name: "NewDEX",
      description: "First global decentralized exchange built on EOS",
      url: "https://newdex.io/",
      image: "newdex.jpeg"
    },
    {
      name: "Bitfinex",
      description: "The largest and most advanced cryptocurrencies exchange",
      url: "https://www.bitfinex.com/",
      image: "bitfinex.png"
    },{
      name: "KuCoin",
      description: "The most advanced cryptocurrency exchange",
      url: "https://www.kucoin.com/#/",
      image: "kucoin.png"
    },{
      name: "Bithumb",
      description: "The largest cryptocurrency exchange in Korea",
      url: "https://www.bithumb.com/",
      image: "bithumb.png"
    },{
      name: "EOSex",
      description: "The Hybrid Exchange for the EOS Community",
      url: "https://eosex.com/#/",
      image: "eosex.png"
    },{
      name: "DEXEOS",
      description: "Decentralized Exchange based on EOS",
      url: "https://dexeos.io/#/",
      image: "dexeos.png"
    },{
      name: "Token.Store",
      description: "A trustless exchange for EOS tokens",
      url: "https://token.store/eos/",
      image: "tokenstore.png"
    },{
      name: "Switcheo",
      description: "Decentralized exchange for EOS, NEO and ETH tokens",
      url: "https://switcheo.exchange/",
      image: "switcheo.jpg"
    }
  ],
  "Help & Dispute": [
    {
      name: "EOS 911",
      description: "Help from scams or lost accounts",
      url: "https://eos911.io/content/welcome",
      image: "eos911.jpg"
    },{
      name: "ECAF",
      description: "Resolution of disputes on the EOSIO blockchain",
      url: "https://eoscorearbitration.io/",
      image: "ecaf.png"
    },{
      name: "LINK",
      description: "An advocate in the crypto community",
      url: "https://thelink.one/",
      image: "link.png"
    }
  ],
  "Developer Resources": [
    {
      name: "Official Dev Portal",
      description: "Get started developing for EOS",
      url: "https://developers.eos.io/",
      image: "eos.png"
    },{
      name: "EOSIO StackExchange",
      description: "question and answer site for users and developers of EOS.IO",
      url: "https://developers.eos.io/",
      image: "stackexchange.png"
    },{
    name: "Awesome EOS Tutorials",
    description: "Collection of great EOS dev tutorials by EOS Asia",
    url: "https://github.com/eosasia/awesome-eos-tutorials",
    image: "eosasia.png"
  },{
    name: "DAPP Development",
    description: "Open source directory of DAPP dev information",
    url: "https://www.eosdocs.io/dappdevelopment/",
    image: "eosdocs.jpg"
  },{
    name: "Node Management Scripts",
    description: "Mainnet Node Management Scripts",
    url: "https://github.com/CryptoLions/EOS-MainNet",
    image: "cryptolions.png"
  },{
    name: "EOS Daily Snapshot",
    description: "Daily snapshots of the EOS Blockchain account holders",
    url: "https://www.eossnapshots.io/",
    image: "eosny.png"
  },{
    name: "EOS Hackathon",
    description: "EOSIO Global Blockchain Hackathon",
    url: "https://eoshackathon.io/",
    image: "eos-hackathon.jpg"
  },{
    name: "QuillAudits",
    description: "Audit platform for EOS based smart contracts and DApps",
    url: "https://audits.quillhash.com/smart-contract-audit",
    image: "quillaudits_logo.png"
  },{
    name: "EOS Infra",
    description: "Stable and Reliable API access for DApps",
    url: "https://eosinfra.io",
    image: "eosinfra.png"
  },{
    name: "EOS Studio",
    description: "A graphic IDE for developing EOSIO DApps",
    url: "https://eosstudio.io",
    image: "eosstudio.jpeg"
  },{
    name: "dfuse",
    description: "Robust and reliable APIs to build next-gen apps on EOS",
    url: "hhttps://www.dfuse.io",
    image: "dfuse.jpg"
  },{
    name: "FireWall X",
    description: "Powerful Firewall For EOS Smart Contract",
    url: "https://firewallx.io/index-en.html",
    image: "firewallx.png"
  }
]
}