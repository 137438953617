import React from 'react'
import styled from 'styled-components'
import C from '../../colors'

const Tile = styled.a`
  display: block;
  border: 1px solid ${C.light};
  color: inherit;
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  line-height: 1.25;
  width: calc(25% - 12px);
  min-width: 200px;
  transition: 0.1s all;

  &:hover {
    text-decoration: none;
    // opacity: 0.8;
    border-color: ${C.gray};
    color: inherit;
  }
  img {
    float: left;
    margin-right: 12px;
    max-width: 50px;
    border-radius: 3px;
  }
  .title {
    color: ${C.dark};
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 2px;
  }
  .description {
    font-size: 12px;
  }

  @media (max-width: 991px) {
    width: calc(33.33% - 12px);
  }
  @media (max-width: 767px) {
    width: calc(50% - 12px);
  }
  @media (max-width: 441px) {
    width: calc(100% - 12px);
    img { max-width: 40px; }
  }
`

const addUtmToUrl = (url) => {
  return url && (url + (url.indexOf('?') !== -1 ? '&' : '?') + 'utm_source=eosgo.io')
}

export default (props) => <Tile
  target="_blank"
  href={addUtmToUrl(props.url) || '#'}
  title={props.url} 
  rel="noopener noreferrer nofollow">
  <img
    src={"/img/icons/" + props.image}
    alt={props.name + ' logo'}/>
  <div className="title">
    {props.name}
  </div>
  <div className="description">
    {props.description}
  </div>
</Tile>