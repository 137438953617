import Eos from 'eosjs'

export const DEFAULT_ENDPOINT = 'https://eos.greymass.com'
export const DEFAULT_CHAIN_ID = 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906'

function _createEos(url) {
  const eosConfig = {
    httpEndpoint: url || DEFAULT_ENDPOINT,
    chainId: DEFAULT_CHAIN_ID,
  }
  return Eos(eosConfig)
}

let _eos = _createEos()

export function eosjs() {
  return _eos
}

// export function getEndpoint() {
//   return DEFAULT_ENDPOINT
// }